import { DeviceFinder } from 'Guide/device_finder';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('WikiRelatedFrameModule', function () {
   WikiRelatedFrameModule.initialize();
});

// eslint-disable-next-line no-var
export var WikiRelatedFrameModule = (window.WikiRelatedFrameModule = {
   initialize: function () {
      when($('wikiRelatedInput'), function (el) {
         DeviceFinder.initialize(el);
      });
   },
});
