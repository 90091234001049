import { Modal } from 'Shared/modal';
import { StatusNotice } from 'Shared/status_notice';

// eslint-disable-next-line no-var
export var PeriodicReviewEditScheduleModal = (window.PeriodicReviewEditScheduleModal = new Class({
   initialize: function () {
      let initialized,
         saveBtn,
         cancelBtn,
         isClosed = true,
         modalContents,
         toggleEnablePeriodicReview,
         absoluteCadenceDateInput,
         relativeCadenceSpanInput,
         absoluteCadenceTypeBtn,
         relativeCadenceTypeBtn,
         gracePeriodInput;

      const notice = new StatusNotice();
      const statusDiv = document.getElementById('periodicReviewStatuses');

      function ensureInitialized() {
         if (initialized) {
            return;
         }
         initialized = true;
         saveBtn = $('reviewScheduleEditSave');
         cancelBtn = $('reviewScheduleEditCancel');
         modalContents = $('periodicReviewModal');
         saveBtn.addEvent('click', ev => {
            ev.stop();
            saveReviewSchedule();
         });

         cancelBtn.addEvent('click', ev => {
            ev.stop();
            close();
         });
      }

      function open() {
         ensureInitialized();
         isClosed = false;
         Modal.open({ type: 'element', element: modalContents, onClose: close });
         toggleEnablePeriodicReview = document.getElementById('reviewScheduleToggle');
         absoluteCadenceDateInput = document.getElementById('absoluteDateInput');
         relativeCadenceSpanInput = document.getElementById('relativeCadenceSpanInput');
         absoluteCadenceTypeBtn = document.getElementById('absoluteCadenceTypeBtn');
         relativeCadenceTypeBtn = document.getElementById('relativeCadenceTypeBtn');
         gracePeriodInput = document.getElementById('gracePeriodInput');
         toggleEnablePeriodicReviewOnInputs();
         updateCadenceTypeInputs();
         preventInvalidGraceAndRelativeSpanInput();
         disableSaveForInvalidOrUnmodifiedInputs();
         let areValuesModified = isScheduleModified();
         if (!areValuesModified) {
            saveBtn.disable();
         }
      }

      function close() {
         if (isClosed) {
            return;
         }
         isClosed = true;
         Modal.close();
      }

      function saveReviewSchedule() {
         saveBtn.disable();
         // If a review schedule existed for the doc, and the enable Periodic Review Checkbox
         // is not selected, remove the doc from the schedule
         if (App.currentReviewScheduleId && !toggleEnablePeriodicReview.checked) {
            if (
               !confirm(
                  /* Translators: %1 -> doc type */
                  _js(
                     'Are you sure you want to remove this Periodic Review Schedule from the %1?',
                     App.docType.toLowerCase()
                  )
               )
            ) {
               saveBtn.enable();
               return;
            }
            removeDocsFromOldSchedule();
         } else {
            if (!App.isAdmin) {
               let confirmSaveMsg =
                  'Are you sure you are ready to save? Once saved, only Admin can make changes to a Periodic Review Schedule';
               if (!confirm(_js(confirmSaveMsg))) {
                  saveBtn.enable();
                  return;
               }
            }
            createPeriodicReviewSchedule();
         }
      }

      function createPeriodicReviewSchedule() {
         let formValues = getFormValues();
         new Request.API_2_0('periodic_review', {
            method: 'POST',
            statusPanelMessage: _js('Saving...'),
            onSuccess: response => {
               let doc = {
                  docid: App.docid,
                  doctype: App.docType,
               };
               let requestBody = {
                  prsid: response[0].prsid,
                  docs: [doc],
               };
               createPeriodicReviewDoc(requestBody);
            },
            onFailure: (response, message) => {
               let errorMessage = (response.responseText || message).replace(/\\\//g, '/');
               notice.error(_js(errorMessage));
               notice.addCloseButton();
               statusDiv.adopt(notice.element);
               saveBtn.enable();
            },
         }).send(formValues);
      }

      function createPeriodicReviewDoc(requestBody) {
         new Request.API_2_0('periodic_review/documents', {
            method: 'POST',
            statusPanelMessage: _js('Saving...'),
            onSuccess: () => {
               if (App.currentReviewScheduleId) {
                  removeDocsFromOldSchedule();
               } else {
                  close();
                  window.location.reload();
               }
            },
            onFailure: (response, message) => {
               let errorMessage = (response.responseText || message).replace(/\\\//g, '/');
               notice.error(_js(errorMessage));
               notice.addCloseButton();
               statusDiv.adopt(notice.element);
               saveBtn.enable();
            },
         }).send(requestBody);
      }

      function removeDocsFromOldSchedule() {
         new Request.AjaxIO('removeDocsFromPreviousReviewSchedule', {
            onSuccess: () => {
               close();
               window.location.reload();
            },
            onError: response => {
               notice.error(_js(response.error.message));
               notice.addCloseButton();
               statusDiv.adopt(notice.element);
               saveBtn.enable();
            },
         }).send(App.docid, App.docType, App.currentReviewScheduleId, App.user);
      }

      function updateCadenceTypeInputs() {
         absoluteCadenceTypeBtn.addEvent('click', () => {
            absoluteCadenceDateInput.enable();
            relativeCadenceSpanInput.disable();
         });
         relativeCadenceTypeBtn.addEvent('click', () => {
            absoluteCadenceDateInput.disable();
            relativeCadenceSpanInput.enable();
         });
      }

      function preventInvalidGraceAndRelativeSpanInput() {
         [gracePeriodInput, relativeCadenceSpanInput].forEach(inputEl => {
            inputEl.addEvent('keydown', ev => {
               let validKeys = [
                  'backspace',
                  'delete',
                  'enter',
                  'tab',
                  'left',
                  'right',
                  'up',
                  'down',
               ];
               if (isNaN(ev.key) && validKeys.indexOf(ev.key) === -1) {
                  ev.preventDefault();
               }
            });
            inputEl.addEvent('input', ev => {
               let selectedCadenceType = document.getElement('input[type=radio]:checked').value;
               let maxLength;
               if (inputEl === relativeCadenceSpanInput) {
                  maxLength = App.maxRelativeCadenceSpan.toString().length;
               } else {
                  if (selectedCadenceType === App.absoluteCadenceType) {
                     maxLength = App.maxAbsoluteGracePeriod.toString().length;
                  } else {
                     maxLength = App.maxGracePeriod.toString().length;
                  }
               }
               if (ev.target.value.length > maxLength) {
                  inputEl.value = ev.target.value.slice(0, -1);
               }
            });
         });
      }

      function getFormValues() {
         const selectedCadenceType = document.getElement('input[type=radio]:checked').value;
         const fullDate = absoluteCadenceDateInput.value.split('-');
         // If the selected cadence type is Relative, use the value of the relative span input
         // If the selected cadence type is Absolute, use the grace period, to prevent errors
         const relativeSpan =
            selectedCadenceType == App.relativeCadenceType
               ? relativeCadenceSpanInput.value
               : gracePeriodInput.value;
         return {
            cadence_type: selectedCadenceType,
            grace: gracePeriodInput.value,
            absolute_cadence_day: fullDate[2],
            absolute_cadence_month: fullDate[1],
            relative_cadence_span: relativeSpan,
         };
      }

      function disableSaveForInvalidOrUnmodifiedInputs() {
         [
            toggleEnablePeriodicReview,
            absoluteCadenceTypeBtn,
            relativeCadenceTypeBtn,
            absoluteCadenceDateInput,
            relativeCadenceSpanInput,
            gracePeriodInput,
         ].forEach(el => {
            el.addEvent('change', ev => {
               let isInputValid = validateInputs(ev);
               let isInputModified = isScheduleModified();
               if (!isInputValid || !isInputModified) {
                  saveBtn.disable();
               } else {
                  saveBtn.enable();
               }
            });
         });
      }

      function validateInputs(ev) {
         statusDiv.empty();
         let fullDate = absoluteCadenceDateInput.value.split('-');
         let selectedCadenceType = document.getElement('input[type=radio]:checked').value;
         let maxGrace =
            selectedCadenceType === App.relativeCadenceType
               ? App.maxGracePeriod
               : App.maxAbsoluteGracePeriod;
         if (!ev.target.value) {
            notice.error(_js('Value cannot be empty'));
            statusDiv.adopt(notice.element);
            return false;
         } else if (
            gracePeriodInput.value.toInt() < App.minGracePeriod ||
            gracePeriodInput.value.toInt() > maxGrace
         ) {
            notice.error(
               /* Translators: %1 -> minimum grace period, %2 -> maximum grace period */
               _js(
                  'Completion Window out of range, values must be between %1 and %2',
                  App.minGracePeriod,
                  maxGrace
               )
            );
            statusDiv.adopt(notice.element);
            return false;
         } else if (
            gracePeriodInput.value.toInt() > relativeCadenceSpanInput.value.toInt() &&
            selectedCadenceType === App.relativeCadenceType
         ) {
            notice.error(_js('Completion Windows must be less than the Relative Cadence Span'));
            statusDiv.adopt(notice.element);
            return false;
         } else if (
            relativeCadenceSpanInput.value.toInt() < App.minRelativeCadenceSpan ||
            relativeCadenceSpanInput.value.toInt() > App.maxRelativeCadenceSpan
         ) {
            notice.error(
               /* Translators: %1 -> minimum relative span, %2 -> maximum relative span */
               _js(
                  'Cadence span out of range, values must be between %1 and %2',
                  App.minRelativeCadenceSpan,
                  App.maxRelativeCadenceSpan
               )
            );
            statusDiv.adopt(notice.element);
            return false;
         } else if (fullDate[2].toInt() < App.minAbsoluteDay || fullDate[2] > App.maxAbsoluteDay) {
            notice.error(
               /* Translators: %1 -> minimum absolute cadence day; %2 -> maximum absolute cadence day */
               _js(
                  'Date out of range, days must be between %1 and %2',
                  App.minAbsoluteDay,
                  App.maxAbsoluteDay
               )
            );
         } else if (
            fullDate[1].toInt() < App.minAbsoluteMonth ||
            fullDate[1].toInt() > App.maxAbsoluteMonth
         ) {
            notice.error(
               /* Translators: %1 -> minimum absolute cadence month; %2 -> maximum absolute cadence month */
               _js(
                  'Date out of range, months must be between %1 and %2',
                  App.minAbsoluteMonth,
                  App.maxAbsoluteMonth
               )
            );
            statusDiv.adopt(notice.element);
            return false;
         }
         return true;
      }

      function isScheduleModified() {
         let areValuesModified = false;
         if (toggleEnablePeriodicReview.checked !== !!App.currentReviewScheduleId) {
            areValuesModified = true;
         } else if (
            document.getElement('input[type=radio]:checked').value.toLowerCase() !==
            App.cadenceType.toLowerCase()
         ) {
            areValuesModified = true;
         } else if (relativeCadenceSpanInput.value.toInt() !== App.relativeSpan.toInt()) {
            areValuesModified = true;
         } else if (gracePeriodInput.value.toInt() !== App.gracePeriod.toInt()) {
            areValuesModified = true;
         }
         let fullDate = absoluteCadenceDateInput.value.split('-');
         if (fullDate[1] !== App.month || fullDate[2] !== App.day) {
            areValuesModified = true;
         }
         return areValuesModified;
      }

      function toggleEnablePeriodicReviewOnInputs() {
         let allInputs = [
            absoluteCadenceTypeBtn,
            relativeCadenceTypeBtn,
            absoluteCadenceDateInput,
            relativeCadenceSpanInput,
            gracePeriodInput,
         ];
         toggleEnablePeriodicReview.addEvent('change', ev => {
            if (ev.target.checked) {
               allInputs.forEach(inputEl => {
                  // If absolute cadence type is selected, disable the relative cadence span input
                  // If relative cadence type is selected, disable the absolute date input
                  if (
                     (inputEl === absoluteCadenceDateInput &&
                        document.getElement('input[type=radio]:checked').value !==
                           App.absoluteCadenceType) ||
                     (inputEl === relativeCadenceSpanInput &&
                        document.getElement('input[type=radio]:checked').value !==
                           App.relativeCadenceType)
                  ) {
                     inputEl.disable();
                  } else {
                     inputEl.enable();
                  }
               });
            } else {
               allInputs.forEach(inputEl => {
                  inputEl.disable();
               });
            }
         });
      }

      this.open = open;
   },
}));
