import { Autocompleter } from 'Shared/Autocompleter/Autocompleter';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('TopicHierarchyFrameModule', function () {
   TopicHierarchy.initialize();
});

// eslint-disable-next-line no-var
export var TopicHierarchy = (window.TopicHierarchy = {
   initialize: function () {
      when($('topicParentForm'), function (form) {
         let wikiid = $('th_wikiid').get('value').toInt();
         let areaInput = $('th_parentTitle');
         let msg = form.getElement('p.statusError');

         // Add the autocompleter.
         let completer = new Autocompleter.Request.JSON(areaInput, '/Guide/JSON/areas', {
            fxOptions: null,
            postVar: 'search',
         });

         let submit = function (ev) {
            completer.hideChoices(true);
            ev.stop();
            let newParentTitle = areaInput.get('value');

            new Request.AjaxIO('wikiHasVariants', {
               onSuccess: function (response) {
                  if (response.hasVariant) {
                     let warningMsg = App.topicInUseWarning.replace(
                        App.topicInUseNameKey,
                        newParentTitle
                     );

                     if (!confirm(warningMsg)) {
                        return;
                     }
                  }

                  new Request.AjaxIO('saveWikiArea', {
                     onSuccess: function (response) {
                        if (!response.error) {
                           window.location.reload();
                        } else {
                           areaInput.blur();
                           msg.set('html', response.message);
                           msg.show().setStyle('opacity', 1);
                           (function () {
                              new Fx.Tween(msg).start('opacity', 0).chain(msg.hide.bind(msg));
                           }.delay(3000));
                        }
                     },
                  }).send(wikiid, newParentTitle);
               },
            }).send(newParentTitle);
         };

         form.addEvent('submit', submit);
         form.getElement('a').addEvent('click', submit);
      });
   },
});
