import * as React from 'react';
import styled from 'styled-components';
import { space, borderRadius, fontSize } from '@dozuki/web-js/primitives';
import { render } from 'react-dom';
import LanguageDropdown from 'Shared/Header/components/dropdowns/language_dropdown';
import trackInMatomoAndGA from 'Shared/Analytics/CombinedGAMatomo';

const LanguageDropdownWrapper = styled(LanguageDropdown)`
   position: absolute;
   right: -${space[4]};
   margin-top: ${space[3]};
   font-size: ${fontSize.md};
   border-radius: ${borderRadius.lg};

   opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
   visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
   transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-1.5rem)')};
   transition: opacity 0.2s linear, transform 0.2s ease-in-out, visibility 0.2s linear;
`;

class LanguageDropdownButton {
   constructor() {
      this.isOpen = false;
      this.props = null;
      this.renderTarget = null;
   }

   setProps = props => (this.props = props);

   setRenderTarget = element => (this.renderTarget = element);

   toggle = event => {
      trackInMatomoAndGA({
         eventCategory: 'Header',
         eventAction: 'click',
         eventName: 'Language',
      });

      this.isOpen = !this.isOpen;

      if (this.isOpen) {
         this.showDropdown(event);
      } else {
         this.hideDropdown(event, true);
      }
   };

   showDropdown = event => {
      event.stop();
      this.renderTarget.classList.remove('invisible');
      document.addEventListener('click', this.hideDropdown);
      this.renderDropdown();
   };

   hideDropdown = (event, force = false) => {
      if (force || !this.renderTarget.contains(event.target)) {
         this.isOpen = false;
         this.renderDropdown();
         document.removeEventListener('click', this.hideDropdown);
         setTimeout(() => this.renderTarget.classList.add('invisible'), 150);
      }
   };

   renderDropdown = () => {
      const {
         languages,
         translationPreferencesUrl,
         machineTranslationEnabled,
         machineTranslationRequested,
      } = this.props;

      render(
         <LanguageDropdownWrapper
            isOpen={this.isOpen}
            languages={languages}
            translationPreferencesUrl={translationPreferencesUrl}
            machineTranslationEnabled={machineTranslationEnabled}
            machineTranslationRequested={machineTranslationRequested}
         />,
         this.renderTarget
      );
   };
}

window.languageDropdownButton = new LanguageDropdownButton();
